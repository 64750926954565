import React, { useEffect, useState, useRef } from "react"
import Constants from "constants"

const InlineVideo = (props) => {
  const [version, setVersion] = useState()
  const [isMute, setIsMute] = useState(true)
  const video = useRef()
  const onResize = () => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < Constants.MQ_LARGE) {
        if (version !== `mobile`) setVersion(`mobile`)
      } else {
        if (version !== `desktop`) setVersion(`desktop`)
      }
    }
  }
  useEffect(() => {
    window.addEventListener(`resize`, onResize)
    onResize()
    return () => {
      window.removeEventListener(`resize`, onResize)
    }
  })
  return (
    <>
      { version &&
      <div className={`${props.className} relative`}>
        <button onClick={props.onClick} className={`w-full h-full`}>
          <video
            className={`w-full h-full`}
            ref={video}
            playsInline
            loop
            autoPlay
            muted={isMute}
            style={props.videoStyle}
          >
            { props.srcDesktop && props.srcMobile && !props.src && (
              <source src={`${version === 'mobile' ? props.srcMobile : props.srcDesktop}`} type={`video/mp4`} />
            )}
            { props.src && (
              <source src={`/${props.src}${version}.mp4`} type={`video/mp4`} />
            )}
          </video>
        </button>
        <button onClick={() => setIsMute(!isMute)} className={`w-5 h-5 lg:w-6 lg:h-6 absolute z-10 bottom-0 right-0 mb-4 mr-4`}>
          <img className={`w-full h-full`} src={`/sound-${isMute ? `off` : `on`}.svg`} />
        </button>
      </div>
      }
    </>
  )
}

export default InlineVideo

import React from 'react'
import Img from 'gatsby-image'
import styles from './index.module.scss'

const ProductBlock = ({ className, hero }) => {
  return (
    <div className={`${className} w-full h-full overflow-hidden`}>
      <Img
        // loading={`lazy`}
        className={styles.img}
        fluid={hero.fluid}
      />
    </div>
  )
}

export default ProductBlock

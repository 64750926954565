import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { useRecoilState, useRecoilValue } from 'recoil'
import { homeSlideshowIndexState, pauseHomeSlideshowState } from 'state'
import useIsMobile from '@/utils/useIsMobile'
import styles from './index.module.scss'
import { getSources } from '@/utils'
import Constants from 'constants'
import Counter from 'ccounter'

const MainSlideshow = ({ slides, className, onClick }) => {
  const [slideIndexState, setSlideIndexState] = useRecoilState(homeSlideshowIndexState, 0)
  const pauseState = useRecoilValue(pauseHomeSlideshowState)
  const [isMobile, setIsMobile] = useState(useIsMobile())
  const allRefs = []
  const allSlides = slides.map((slide, i) => {
    const slideRef = React.createRef()
    allRefs.push(slideRef)
    const fit = slide.appearance === `Fullscreen` ? { objectFit: 'cover' } : { objectFit: 'contain' }
    const size = (slide.appearance === `Small Center`) ? `w-full h-full ${styles.center}` : `w-full h-full`
    const fluid = getSources(slide)
    return (
      <div id={i} ref={slideRef} className={`absolute w-full h-full`} key={`${slide.id}-slideshow-image`}>
        <Img
          loading={`eager`}
          imgStyle={fit}
          className={`${styles.img} ${size}`}
          fluid={fluid}
        />
      </div>
    )
  })
  const onMouseEnter = (e) => {
    const areaId = e.currentTarget.id
    setSlideIndexState(areaId)
  }
  const areas = slides.map((slide, i) => {
    return <button aria-label={`slide-area`} id={i} tabIndex={0} onMouseEnter={onMouseEnter} key={`${slide.id}-slideshow-area`} className={`flex-1 h-full cursor-leftRight`} />
  })
  useEffect(() => {
    allRefs.forEach(ref => {
      if (ref.current.id === slideIndexState) {
        ref.current.style.opacity = 1
      } else {
        ref.current.style.opacity = 0
      }
    })
  })
  useEffect(() => {
    let loop
    if (pauseState) {
      clearInterval(loop)
      return
    }
    if (!isMobile) return
    const onLoop = () => {
      counter.inc()
      setSlideIndexState(counter.props.index.toString())
    }
    const counter = new Counter(slides.length)
    loop = setInterval(onLoop, 1400)
    setIsMobile(window.innerWidth <= Constants.MQ_LARGE)
    return () => {
      clearInterval(loop)
    }
  }, [setIsMobile, isMobile, slides.length, setSlideIndexState, pauseState])
  return (
    <div onClick={onClick} onKeyDown={onClick} role='button' tabIndex={0} className={`${className} relative`}>
      { !pauseState && <div className={`items-stretch w-full h-full absolute z-10 hidden lg:flex`}>{ areas }</div>}
      <div className={`absolute w-full h-full`}>
        {allSlides}
      </div>
    </div>
  )
}

export default MainSlideshow
